<template>
  <div class="policy">
    <div class="title">服務政策</div>
    <el-divider />
    <div class="content">
      聲明<br />
      ChunLin致力滿足顧客的需求，顧客對我們的信任是無比的重要，因此ChunLin高度重視保護每一位顧客的個人資料。<br />
      為確保所有收集得來的資料時刻處於安全的水平，我們採取了合理的安全措施來保護顧客提供的資料及訊息。<br /><br />

      私隱政策<br />
      ChunLin已建立並正實施以下各項個人訊息保護政策:<br /><br />

      收集個人資料 <br />
      當閣下透過任何渠道 （包括但不局限於此網站） 向ChunLin提供個人資料，均於了解相關法則的情況下自願提供，而閣下亦已明白及同意有關資料的使用用途。<br />
      提供及使用個人資料<br />
      閣下的個人資料只會在以下情況使用:<br />
      透過ChunLin官方渠道（包括但不局限於此網站），在取得您的同意後使用個人資料<br />
      依據法律規定或法院命令下必須提供閣下的個人資料。<br />
      在一般的情況及受到法律的監管下， ChunLin不會向第三者披露閣下的個人資料。<br />
      正確管理個人資料<br />
      為防止任何未經授權而取得閣下的個人資料，而導致損失、破壞、偽造或洩露的情況出現，ChunLin將盡力保持閣下的資料正確及更新。<br />
      驗證及更改個人資料<br />
      若我們收到任何關於查詢、確認、更改或是暫停使用閣下個人資料的指示時，我們會先與閣下確認身份，才回應有關查詢。<br />
      改善個人資料保護政策及承諾<br />
      遵循中華人民共和國的法律，我們會時刻檢討以上各項政策，為閣下提供最完善的保障，及保護閣下的個人資料。<br />
      個人資料處理指引<br />
      為採取適當措施來保護閣下的個人資料，我們制定了一套私隱政策以確保成效。而有關閣下的個人資料處理，請參閱下列各項:<br /><br />

      使用目的<br />
      在閣下同意而取得的個人資料，我們將用於以下各方面 （包括但不局限於）:<br /><br />

      通知閣下有關ChunLin最新產品資訊 （包括珠寶、鐘錶、配飾等等）<br />
      通知閣下有關ChunLin售後服務資訊<br />
      邀請閣下參與展覽、活動、研討會等<br />
      進行市場研究 （如問卷調查等）<br />
      通知閣下有關ChunLin的最新動向<br />
      保護及管理個人資料<br />
      依照香港法例，我們會盡力保障閣下個人資料安全，防止任何未經授權而取得閣下的個人資料，而導致損失、破壞、偽造洩露的情況出現。在我們指定承辦商有可能接觸閣下個人資料的情況下，我們亦會採取適當的措施，以確保閣下個人資料的安全受到保障。<br />
    </div>
  </div>
</template>

<style lang="less" scoped>
.policy {
  padding: 0 3.2vw;
  margin-bottom: 4vw;

  .title {
    font-size: 3.7333vw;
    font-weight: 400;
    line-height: 5.6vw;
    text-align: center;
    margin-top: 6.4vw;
  }

  .el-divider {
    margin-top: 2.8vw;
  }

  .content {
    font-size: 3.2vw;
    font-weight: 400;
    color: #3D3D3D;
    line-height: 4.8vw;
  }
}
</style>
